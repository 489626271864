import React from 'react';
import { Link } from 'gatsby';

import '../css/link.css';

export default function Clink({ children, className, to }) {
  return (
    <Link className={[`link`].concat(className || []).join(' ')} to={to}>
      {children}
    </Link>
  );
}
