import React from 'react';
import { Link } from 'gatsby';
import { FaHome } from 'react-icons/fa';
import { FaTags } from 'react-icons/fa';

import Layout from '../components/Layout';
import Clink from '../components/Link';

import '../css/tags.css';

function Tags({ posts, post, tag }) {
  if (tag) {
    return (
      <div className="tag-container">
        <div className="tag-content">
            <h1>
            {post.length} post{post.length === 1 ? '' : 's'} tagged with {tag}
            </h1>
            <ul>
            {post.map(({ id, frontmatter, excerpt }) => {
                return (
                <li key={id}>
                    <h1>
                    <Link to={frontmatter.path}>
                        {frontmatter.title}
                    </Link>
                    </h1>
                    <p>
                    {excerpt}
                    </p>
                </li>
                );
            })}
            </ul>
            <Clink to="/tags">
                <FaTags /> All tags
            </Clink>
        </div>
      </div>
    );
  } else {
    return (
        <div className="tag-container">
            <div className="tag-content">
                <h1>Tags</h1>
                <ul className="tags">
                    {Object.keys(posts).map(tagName => <li key={tagName}>
                        <Link to={`/tags/${tagName}`}>
                            {tagName}
                        </Link>
                        </li>)}
                </ul>
                <Clink to="/">
                    <FaHome /> All posts
                </Clink>
            </div>
        </div>
    );
  }
}   

export default function TagsTemplate(props) {
  const { pageContext } = props;
  return (
    <Layout {...props}><Tags {...pageContext} /></Layout>
  );
}
