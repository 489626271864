import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';

import '../css/nav.css';

export default function Nav() {
    return (
        <div className="nav">
            <ul className="nav-list">
                <li>
                    <Link to={`/`}>blog</Link>
                </li>
                <li>
                    <Link to={`/stuff`}>stuff</Link>
                </li>
                <li>
                    <Link to={`/tags`}>tags</Link>
                </li>
            </ul>
        </div>
    )
}
